
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "path": "/home",
    "label": "Home",
    "icon": "home",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/menu24",
    "label": "Stores",
    "icon": "local_grocery_store",
    "iconcolor": "",
    "target": "",
    "submenu": [
      {
        "path": "/storehome",
        "label": "Store Home",
        "icon": "storefront",
        "iconcolor": "",
        "target": "",
        "submenu": []
      }
    ]
  },
  {
    "path": "/menu25",
    "label": "WPD",
    "icon": "streetview",
    "iconcolor": "",
    "target": "",
    "submenu": [
      {
        "path": "/wpdhome",
        "label": "WPD Home",
        "icon": "wb_iridescent",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/wpdproduction",
        "label": "In & Out",
        "icon": "fax",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/equipment/wpd",
        "label": "Machine",
        "icon": "hive",
        "iconcolor": "",
        "target": "",
        "submenu": []
      }
    ]
  },
  {
    "path": "/menu26",
    "label": " Weaving",
    "icon": "view_agenda",
    "iconcolor": "",
    "target": "",
    "submenu": [
      {
        "path": "/weavinghome",
        "label": "Weaving Home",
        "icon": "water",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/weavingproduction",
        "label": "In & Out",
        "icon": "pin",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/equipment/weaving",
        "label": "Machine",
        "icon": "ac_unit",
        "iconcolor": "",
        "target": "",
        "submenu": []
      }
    ]
  },
  {
    "path": "/menu27",
    "label": "Grey Inspection",
    "icon": "view_compact",
    "iconcolor": "",
    "target": "",
    "submenu": [
      {
        "path": "/greyinspectionhome",
        "label": "Grey Inspection Home",
        "icon": "bug_report",
        "iconcolor": "",
        "target": "",
        "submenu": []
      }
    ]
  },
  {
    "path": "/menu28",
    "label": "Processing",
    "icon": "wallpaper",
    "iconcolor": "",
    "target": "",
    "submenu": [
      {
        "path": "/processinghome",
        "label": "Processing Home",
        "icon": "memory",
        "iconcolor": "",
        "target": "",
        "submenu": []
      }
    ]
  },
  {
    "path": "/menu29",
    "label": "Processing Inspection",
    "icon": "view_quilt",
    "iconcolor": "",
    "target": "",
    "submenu": [
      {
        "path": "/processinginspectionhome",
        "label": "Processing Inspection Home",
        "icon": "pest_control",
        "iconcolor": "",
        "target": "",
        "submenu": []
      }
    ]
  },
  {
    "path": "/menu30",
    "label": "Confection",
    "icon": "center_focus_weak",
    "iconcolor": "",
    "target": "",
    "submenu": [
      {
        "path": "/confectionhome",
        "label": "Confection Home",
        "icon": "confirmation_number",
        "iconcolor": "",
        "target": "",
        "submenu": []
      }
    ]
  },
  {
    "path": "/menu31",
    "label": "Finished Goods",
    "icon": "card_giftcard",
    "iconcolor": "",
    "target": "",
    "submenu": [
      {
        "path": "/finishedgoodshome",
        "label": "Finished Goods Home",
        "icon": "check_circle_outline",
        "iconcolor": "",
        "target": "",
        "submenu": []
      }
    ]
  },
  {
    "path": "/menu32",
    "label": "Store Masters",
    "icon": "picture_as_pdf",
    "iconcolor": "",
    "target": "",
    "submenu": [
      {
        "path": "/storebatchmas",
        "label": "Store Batchmas",
        "icon": "travel_explore",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/storegrn",
        "label": "Store Grn",
        "icon": "precision_manufacturing",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/storeissue",
        "label": "Store Issue",
        "icon": "confirmation_number",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/storesuppliers",
        "label": "Store Suppliers",
        "icon": "inventory_2",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/store",
        "label": "Store",
        "icon": "local_grocery_store",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/product",
        "label": "Product",
        "icon": "category",
        "iconcolor": "",
        "target": "",
        "submenu": []
      }
    ]
  },
  {
    "path": "/menu33",
    "label": "Masters",
    "icon": "badge",
    "iconcolor": "",
    "target": "",
    "submenu": [
      {
        "path": "/equipmentworkf",
        "label": "Equipment Work",
        "icon": "diversity_2",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/equipmentworkflow",
        "label": "Equipment Workflow",
        "icon": "sensor_occupied",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/workflow",
        "label": "Workflow",
        "icon": "sensors",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/equipmentworkflowmanagement",
        "label": "Equipment Workflow Management",
        "icon": "diversity_1",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/workflowmanagement",
        "label": "Workflow Management",
        "icon": "stream",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/equipmentmaintenance",
        "label": "Equipment Maintenance",
        "icon": "engineering",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/equipment",
        "label": "Equipment",
        "icon": "home_repair_service",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/location",
        "label": "Location",
        "icon": "explore",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/equipmentrepairs",
        "label": "Equipment Repairs",
        "icon": "handyman",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/employee",
        "label": "Employee",
        "icon": "reduce_capacity",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/workf",
        "label": "Work",
        "icon": "maps_home_work",
        "iconcolor": "",
        "target": "",
        "submenu": []
      }
    ]
  },
  {
    "path": "/menu34",
    "label": "Settings",
    "icon": "settings_suggest",
    "iconcolor": "",
    "target": "",
    "submenu": [
      {
        "path": "/users",
        "label": "Users",
        "icon": "group_add",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/industry",
        "label": "Industry",
        "icon": "factory",
        "iconcolor": "",
        "target": "",
        "submenu": []
      }
    ]
  },
  {
    "path": "/workf",
    "label": "Workf",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/production",
    "label": "Production",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/production_month",
    "label": "Production Month",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/iotdata",
    "label": "Iotdata",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  }
],
	employeelistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Employee Id",
    "align": "left",
    "sortable": false,
    "name": "employee_id",
    "field": "employee_id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Nationality",
    "align": "left",
    "sortable": false,
    "name": "nationality",
    "field": "nationality"
  },
  {
    "label": "Birthday",
    "align": "left",
    "sortable": false,
    "name": "birthday",
    "field": "birthday"
  },
  {
    "label": "Gender",
    "align": "left",
    "sortable": false,
    "name": "gender",
    "field": "gender"
  },
  {
    "label": "Marital Status",
    "align": "left",
    "sortable": false,
    "name": "marital_status",
    "field": "marital_status"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	gender: [    
{value: "Male", label: "Male"},
	{value: "Female", label: "Female"}
    ],
	maritalStatus: [    
{value: "Married", label: "Married"},
	{value: "Single", label: "Single"},
	{value: "Divorced", label: "Divorced"},
	{value: "Widowed", label: "Widowed"},
	{value: "Other", label: "Other"}
    ],
	equipmentlistheader: [
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Serial Number",
    "align": "left",
    "sortable": false,
    "name": "serial_number",
    "field": "serial_number"
  },
  {
    "label": "Model Number",
    "align": "left",
    "sortable": false,
    "name": "model_number",
    "field": "model_number"
  },
  {
    "label": "Location",
    "align": "left",
    "sortable": false,
    "name": "location",
    "field": "location"
  },
  {
    "label": "Purchase Date",
    "align": "left",
    "sortable": false,
    "name": "purchase_date",
    "field": "purchase_date"
  },
  {
    "label": "Warranty Expiration Date",
    "align": "left",
    "sortable": false,
    "name": "warranty_expiration_date",
    "field": "warranty_expiration_date"
  },
  {
    "label": "Currentcontidition",
    "align": "left",
    "sortable": false,
    "name": "currentcontidition",
    "field": "currentcontidition"
  },
  {
    "label": "Currentstatus",
    "align": "left",
    "sortable": false,
    "name": "currentstatus",
    "field": "currentstatus"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	currentcontidition: [    
{value: "Working", label: "Working"},
	{value: "Breakdown", label: "Breakdown"}
    ],
	currentstatus: [    
{value: "On", label: "On"},
	{value: "Off", label: "Off"}
    ],
	equipmentwpdheader: [
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	equipmentmaintenancelistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Equipment Id",
    "align": "left",
    "sortable": false,
    "name": "equipment_id",
    "field": "equipment_id"
  },
  {
    "label": "Maintenance Date",
    "align": "left",
    "sortable": false,
    "name": "maintenance_date",
    "field": "maintenance_date"
  },
  {
    "label": "Maintenance Start Date",
    "align": "left",
    "sortable": false,
    "name": "maintenance_start_date",
    "field": "maintenance_start_date"
  },
  {
    "label": "Maintenance Expiration Date",
    "align": "left",
    "sortable": false,
    "name": "maintenance_expiration_date",
    "field": "maintenance_expiration_date"
  },
  {
    "label": "Type",
    "align": "left",
    "sortable": false,
    "name": "type",
    "field": "type"
  },
  {
    "label": "Technician",
    "align": "left",
    "sortable": false,
    "name": "technician",
    "field": "technician"
  },
  {
    "label": "Price",
    "align": "left",
    "sortable": false,
    "name": "price",
    "field": "price"
  },
  {
    "label": "Notes",
    "align": "left",
    "sortable": false,
    "name": "notes",
    "field": "notes"
  },
  {
    "label": "Industryid",
    "align": "left",
    "sortable": false,
    "name": "industryid",
    "field": "industryid"
  },
  {
    "label": "Repid",
    "align": "left",
    "sortable": false,
    "name": "repid",
    "field": "repid"
  },
  {
    "label": "Branchid",
    "align": "left",
    "sortable": false,
    "name": "branchid",
    "field": "branchid"
  },
  {
    "label": "Storeid",
    "align": "left",
    "sortable": false,
    "name": "storeid",
    "field": "storeid"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	equipmentrepairslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Equipment Id",
    "align": "left",
    "sortable": false,
    "name": "equipment_id",
    "field": "equipment_id"
  },
  {
    "label": "Repair Date",
    "align": "left",
    "sortable": false,
    "name": "repair_date",
    "field": "repair_date"
  },
  {
    "label": "Type",
    "align": "left",
    "sortable": false,
    "name": "type",
    "field": "type"
  },
  {
    "label": "Technician",
    "align": "left",
    "sortable": false,
    "name": "technician",
    "field": "technician"
  },
  {
    "label": "Price",
    "align": "left",
    "sortable": false,
    "name": "price",
    "field": "price"
  },
  {
    "label": "Notes",
    "align": "left",
    "sortable": false,
    "name": "notes",
    "field": "notes"
  },
  {
    "label": "Equipment Status",
    "align": "left",
    "sortable": false,
    "name": "equipment_status",
    "field": "equipment_status"
  },
  {
    "label": "Industryid",
    "align": "left",
    "sortable": false,
    "name": "industryid",
    "field": "industryid"
  },
  {
    "label": "Repid",
    "align": "left",
    "sortable": false,
    "name": "repid",
    "field": "repid"
  },
  {
    "label": "Branchid",
    "align": "left",
    "sortable": false,
    "name": "branchid",
    "field": "branchid"
  },
  {
    "label": "Storeid",
    "align": "left",
    "sortable": false,
    "name": "storeid",
    "field": "storeid"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	equipmentworkflistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Work Name",
    "align": "left",
    "sortable": false,
    "name": "work_name",
    "field": "work_name"
  },
  {
    "label": "Work Description",
    "align": "left",
    "sortable": false,
    "name": "work_description",
    "field": "work_description"
  },
  {
    "label": "Work Definition",
    "align": "left",
    "sortable": false,
    "name": "work_definition",
    "field": "work_definition"
  },
  {
    "label": "Working Hour",
    "align": "left",
    "sortable": false,
    "name": "working_hour",
    "field": "working_hour"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	equipmentworkflowlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Work Id",
    "align": "left",
    "sortable": false,
    "name": "work_id",
    "field": "work_id"
  },
  {
    "label": "Employee Id",
    "align": "left",
    "sortable": false,
    "name": "employee_id",
    "field": "employee_id"
  },
  {
    "label": "Location Id",
    "align": "left",
    "sortable": false,
    "name": "location_id",
    "field": "location_id"
  },
  {
    "label": "Equipment Id",
    "align": "left",
    "sortable": false,
    "name": "equipment_id",
    "field": "equipment_id"
  },
  {
    "label": "Work Name",
    "align": "left",
    "sortable": false,
    "name": "work_name",
    "field": "work_name"
  },
  {
    "label": "Task Start",
    "align": "left",
    "sortable": false,
    "name": "task_start",
    "field": "task_start"
  },
  {
    "label": "Task End",
    "align": "left",
    "sortable": false,
    "name": "task_end",
    "field": "task_end"
  },
  {
    "label": "Work Instance Status",
    "align": "left",
    "sortable": false,
    "name": "work_instance_status",
    "field": "work_instance_status"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Working Hour",
    "align": "left",
    "sortable": false,
    "name": "working_hour",
    "field": "working_hour"
  },
  {
    "label": "Workflow Description",
    "align": "left",
    "sortable": false,
    "name": "workflow_description",
    "field": "workflow_description"
  },
  {
    "label": "Workflow Definition",
    "align": "left",
    "sortable": false,
    "name": "workflow_definition",
    "field": "workflow_definition"
  },
  {
    "label": "Industryid",
    "align": "left",
    "sortable": false,
    "name": "industryid",
    "field": "industryid"
  },
  {
    "label": "Repid",
    "align": "left",
    "sortable": false,
    "name": "repid",
    "field": "repid"
  },
  {
    "label": "Branchid",
    "align": "left",
    "sortable": false,
    "name": "branchid",
    "field": "branchid"
  },
  {
    "label": "Storeid",
    "align": "left",
    "sortable": false,
    "name": "storeid",
    "field": "storeid"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	equipmentworkflowmanagementlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Workflow Id",
    "align": "left",
    "sortable": false,
    "name": "workflow_id",
    "field": "workflow_id"
  },
  {
    "label": "Task Start",
    "align": "left",
    "sortable": false,
    "name": "task_start",
    "field": "task_start"
  },
  {
    "label": "Task End",
    "align": "left",
    "sortable": false,
    "name": "task_end",
    "field": "task_end"
  },
  {
    "label": "Progress Count",
    "align": "left",
    "sortable": false,
    "name": "progress_count",
    "field": "progress_count"
  },
  {
    "label": "Workflow Description",
    "align": "left",
    "sortable": false,
    "name": "workflow_description",
    "field": "workflow_description"
  },
  {
    "label": "Workflow Definition",
    "align": "left",
    "sortable": false,
    "name": "workflow_definition",
    "field": "workflow_definition"
  },
  {
    "label": "Workflow Status",
    "align": "left",
    "sortable": false,
    "name": "workflow_status",
    "field": "workflow_status"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Industryid",
    "align": "left",
    "sortable": false,
    "name": "industryid",
    "field": "industryid"
  },
  {
    "label": "Repid",
    "align": "left",
    "sortable": false,
    "name": "repid",
    "field": "repid"
  },
  {
    "label": "Branchid",
    "align": "left",
    "sortable": false,
    "name": "branchid",
    "field": "branchid"
  },
  {
    "label": "Storeid",
    "align": "left",
    "sortable": false,
    "name": "storeid",
    "field": "storeid"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	industrylistheader: [
  {
    "label": "Logo",
    "align": "left",
    "sortable": false,
    "name": "logo",
    "field": "logo"
  },
  {
    "label": "Industry",
    "align": "left",
    "sortable": false,
    "name": "industry",
    "field": "industry"
  },
  {
    "label": "Street",
    "align": "left",
    "sortable": false,
    "name": "street",
    "field": "street"
  },
  {
    "label": "Area",
    "align": "left",
    "sortable": false,
    "name": "area",
    "field": "area"
  },
  {
    "label": "Town",
    "align": "left",
    "sortable": false,
    "name": "town",
    "field": "town"
  },
  {
    "label": "Phone No",
    "align": "left",
    "sortable": false,
    "name": "phone_no",
    "field": "phone_no"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Website",
    "align": "left",
    "sortable": false,
    "name": "website",
    "field": "website"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	iotdatalistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Iotid",
    "align": "left",
    "sortable": false,
    "name": "iotid",
    "field": "iotid"
  },
  {
    "label": "Iotmachineid",
    "align": "left",
    "sortable": false,
    "name": "iotmachineid",
    "field": "iotmachineid"
  },
  {
    "label": "Sensor",
    "align": "left",
    "sortable": false,
    "name": "sensor",
    "field": "sensor"
  },
  {
    "label": "Room",
    "align": "left",
    "sortable": false,
    "name": "room",
    "field": "room"
  },
  {
    "label": "Machine",
    "align": "left",
    "sortable": false,
    "name": "machine",
    "field": "machine"
  },
  {
    "label": "Value",
    "align": "left",
    "sortable": false,
    "name": "value",
    "field": "value"
  },
  {
    "label": "Valuestr",
    "align": "left",
    "sortable": false,
    "name": "valuestr",
    "field": "valuestr"
  },
  {
    "label": "Datetime",
    "align": "left",
    "sortable": false,
    "name": "datetime",
    "field": "datetime"
  },
  {
    "label": "Industryid",
    "align": "left",
    "sortable": false,
    "name": "industryid",
    "field": "industryid"
  },
  {
    "label": "Repid",
    "align": "left",
    "sortable": false,
    "name": "repid",
    "field": "repid"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	locationlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Address",
    "align": "left",
    "sortable": false,
    "name": "address",
    "field": "address"
  },
  {
    "label": "City",
    "align": "left",
    "sortable": false,
    "name": "city",
    "field": "city"
  },
  {
    "label": "State",
    "align": "left",
    "sortable": false,
    "name": "state",
    "field": "state"
  },
  {
    "label": "Zip Code",
    "align": "left",
    "sortable": false,
    "name": "zip_code",
    "field": "zip_code"
  },
  {
    "label": "Industryid",
    "align": "left",
    "sortable": false,
    "name": "industryid",
    "field": "industryid"
  },
  {
    "label": "Repid",
    "align": "left",
    "sortable": false,
    "name": "repid",
    "field": "repid"
  },
  {
    "label": "Branchid",
    "align": "left",
    "sortable": false,
    "name": "branchid",
    "field": "branchid"
  },
  {
    "label": "Storeid",
    "align": "left",
    "sortable": false,
    "name": "storeid",
    "field": "storeid"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	notificationlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Target",
    "align": "left",
    "sortable": false,
    "name": "target",
    "field": "target"
  },
  {
    "label": "Message",
    "align": "left",
    "sortable": false,
    "name": "message",
    "field": "message"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Datetime",
    "align": "left",
    "sortable": false,
    "name": "datetime",
    "field": "datetime"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	productlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Version",
    "align": "left",
    "sortable": false,
    "name": "version",
    "field": "version"
  },
  {
    "label": "Category Id",
    "align": "left",
    "sortable": false,
    "name": "category_id",
    "field": "category_id"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Description",
    "align": "left",
    "sortable": false,
    "name": "description",
    "field": "description"
  },
  {
    "label": "Last Updated",
    "align": "left",
    "sortable": false,
    "name": "last_updated",
    "field": "last_updated"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Product Code",
    "align": "left",
    "sortable": false,
    "name": "product_code",
    "field": "product_code"
  },
  {
    "label": "Unit Of Measure Id",
    "align": "left",
    "sortable": false,
    "name": "unit_of_measure_id",
    "field": "unit_of_measure_id"
  },
  {
    "label": "Cold Chain",
    "align": "left",
    "sortable": false,
    "name": "cold_chain",
    "field": "cold_chain"
  },
  {
    "label": "Manufacturer",
    "align": "left",
    "sortable": false,
    "name": "manufacturer",
    "field": "manufacturer"
  },
  {
    "label": "Manufacturer Code",
    "align": "left",
    "sortable": false,
    "name": "manufacturer_code",
    "field": "manufacturer_code"
  },
  {
    "label": "Ndc",
    "align": "left",
    "sortable": false,
    "name": "ndc",
    "field": "ndc"
  },
  {
    "label": "Upc",
    "align": "left",
    "sortable": false,
    "name": "upc",
    "field": "upc"
  },
  {
    "label": "Unit Of Measure",
    "align": "left",
    "sortable": false,
    "name": "unit_of_measure",
    "field": "unit_of_measure"
  },
  {
    "label": "Created By Id",
    "align": "left",
    "sortable": false,
    "name": "created_by_id",
    "field": "created_by_id"
  },
  {
    "label": "Updated By Id",
    "align": "left",
    "sortable": false,
    "name": "updated_by_id",
    "field": "updated_by_id"
  },
  {
    "label": "Default Uom Id",
    "align": "left",
    "sortable": false,
    "name": "default_uom_id",
    "field": "default_uom_id"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	productionlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Date",
    "align": "left",
    "sortable": false,
    "name": "date",
    "field": "date"
  },
  {
    "label": "From",
    "align": "left",
    "sortable": false,
    "name": "from",
    "field": "from"
  },
  {
    "label": "To",
    "align": "left",
    "sortable": false,
    "name": "to",
    "field": "to"
  },
  {
    "label": "Order",
    "align": "left",
    "sortable": false,
    "name": "order",
    "field": "order"
  },
  {
    "label": "Count",
    "align": "left",
    "sortable": false,
    "name": "count",
    "field": "count"
  },
  {
    "label": "User",
    "align": "left",
    "sortable": false,
    "name": "user",
    "field": "user"
  },
  {
    "label": "Industryid",
    "align": "left",
    "sortable": false,
    "name": "industryid",
    "field": "industryid"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	productionmonthlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Date",
    "align": "left",
    "sortable": false,
    "name": "date",
    "field": "date"
  },
  {
    "label": "From",
    "align": "left",
    "sortable": false,
    "name": "from",
    "field": "from"
  },
  {
    "label": "To",
    "align": "left",
    "sortable": false,
    "name": "to",
    "field": "to"
  },
  {
    "label": "Order",
    "align": "left",
    "sortable": false,
    "name": "order",
    "field": "order"
  },
  {
    "label": "Count",
    "align": "left",
    "sortable": false,
    "name": "count",
    "field": "count"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	storelistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Logo",
    "align": "left",
    "sortable": false,
    "name": "logo",
    "field": "logo"
  },
  {
    "label": "Store",
    "align": "left",
    "sortable": false,
    "name": "store",
    "field": "store"
  },
  {
    "label": "Street",
    "align": "left",
    "sortable": false,
    "name": "street",
    "field": "street"
  },
  {
    "label": "Area",
    "align": "left",
    "sortable": false,
    "name": "area",
    "field": "area"
  },
  {
    "label": "Town",
    "align": "left",
    "sortable": false,
    "name": "town",
    "field": "town"
  },
  {
    "label": "Province",
    "align": "left",
    "sortable": false,
    "name": "province",
    "field": "province"
  },
  {
    "label": "Postal Code",
    "align": "left",
    "sortable": false,
    "name": "postal_code",
    "field": "postal_code"
  },
  {
    "label": "Phone No",
    "align": "left",
    "sortable": false,
    "name": "phone_no",
    "field": "phone_no"
  },
  {
    "label": "Prefixcode",
    "align": "left",
    "sortable": false,
    "name": "prefixcode",
    "field": "prefixcode"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Createdby",
    "align": "left",
    "sortable": false,
    "name": "createdby",
    "field": "createdby"
  },
  {
    "label": "Createddatetime",
    "align": "left",
    "sortable": false,
    "name": "createddatetime",
    "field": "createddatetime"
  },
  {
    "label": "Modifiedby",
    "align": "left",
    "sortable": false,
    "name": "modifiedby",
    "field": "modifiedby"
  },
  {
    "label": "Modifieddatetime",
    "align": "left",
    "sortable": false,
    "name": "modifieddatetime",
    "field": "modifieddatetime"
  },
  {
    "label": "Billinggst",
    "align": "left",
    "sortable": false,
    "name": "billinggst",
    "field": "billinggst"
  },
  {
    "label": "Country",
    "align": "left",
    "sortable": false,
    "name": "country",
    "field": "country"
  },
  {
    "label": "Industryid",
    "align": "left",
    "sortable": false,
    "name": "industryid",
    "field": "industryid"
  },
  {
    "label": "Repid",
    "align": "left",
    "sortable": false,
    "name": "repid",
    "field": "repid"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	storebatchmaslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Prc",
    "align": "left",
    "sortable": false,
    "name": "prc",
    "field": "prc"
  },
  {
    "label": "Batchno",
    "align": "left",
    "sortable": false,
    "name": "batchno",
    "field": "batchno"
  },
  {
    "label": "Oq",
    "align": "left",
    "sortable": false,
    "name": "oq",
    "field": "oq"
  },
  {
    "label": "Rq",
    "align": "left",
    "sortable": false,
    "name": "rq",
    "field": "rq"
  },
  {
    "label": "Mrq",
    "align": "left",
    "sortable": false,
    "name": "mrq",
    "field": "mrq"
  },
  {
    "label": "Iq",
    "align": "left",
    "sortable": false,
    "name": "iq",
    "field": "iq"
  },
  {
    "label": "Mrp",
    "align": "left",
    "sortable": false,
    "name": "mrp",
    "field": "mrp"
  },
  {
    "label": "Expdt",
    "align": "left",
    "sortable": false,
    "name": "expdt",
    "field": "expdt"
  },
  {
    "label": "Ur",
    "align": "left",
    "sortable": false,
    "name": "ur",
    "field": "ur"
  },
  {
    "label": "Rt",
    "align": "left",
    "sortable": false,
    "name": "rt",
    "field": "rt"
  },
  {
    "label": "Prcode",
    "align": "left",
    "sortable": false,
    "name": "prcode",
    "field": "prcode"
  },
  {
    "label": "Batch",
    "align": "left",
    "sortable": false,
    "name": "batch",
    "field": "batch"
  },
  {
    "label": "Pc",
    "align": "left",
    "sortable": false,
    "name": "pc",
    "field": "pc"
  },
  {
    "label": "Oldrt",
    "align": "left",
    "sortable": false,
    "name": "oldrt",
    "field": "oldrt"
  },
  {
    "label": "Tempmrq",
    "align": "left",
    "sortable": false,
    "name": "tempmrq",
    "field": "tempmrq"
  },
  {
    "label": "Taxp",
    "align": "left",
    "sortable": false,
    "name": "taxp",
    "field": "taxp"
  },
  {
    "label": "Oldrate",
    "align": "left",
    "sortable": false,
    "name": "oldrate",
    "field": "oldrate"
  },
  {
    "label": "Newrate",
    "align": "left",
    "sortable": false,
    "name": "newrate",
    "field": "newrate"
  },
  {
    "label": "Otempmra",
    "align": "left",
    "sortable": false,
    "name": "otempmra",
    "field": "otempmra"
  },
  {
    "label": "Activestatus",
    "align": "left",
    "sortable": false,
    "name": "activestatus",
    "field": "activestatus"
  },
  {
    "label": "Prname",
    "align": "left",
    "sortable": false,
    "name": "prname",
    "field": "prname"
  },
  {
    "label": "Psgst",
    "align": "left",
    "sortable": false,
    "name": "psgst",
    "field": "psgst"
  },
  {
    "label": "Pcgst",
    "align": "left",
    "sortable": false,
    "name": "pcgst",
    "field": "pcgst"
  },
  {
    "label": "Ssgst",
    "align": "left",
    "sortable": false,
    "name": "ssgst",
    "field": "ssgst"
  },
  {
    "label": "Scgst",
    "align": "left",
    "sortable": false,
    "name": "scgst",
    "field": "scgst"
  },
  {
    "label": "Mfrcode",
    "align": "left",
    "sortable": false,
    "name": "mfrcode",
    "field": "mfrcode"
  },
  {
    "label": "Brcode",
    "align": "left",
    "sortable": false,
    "name": "brcode",
    "field": "brcode"
  },
  {
    "label": "Frq",
    "align": "left",
    "sortable": false,
    "name": "frq",
    "field": "frq"
  },
  {
    "label": "Um",
    "align": "left",
    "sortable": false,
    "name": "um",
    "field": "um"
  },
  {
    "label": "Genname",
    "align": "left",
    "sortable": false,
    "name": "genname",
    "field": "genname"
  },
  {
    "label": "Billdate",
    "align": "left",
    "sortable": false,
    "name": "billdate",
    "field": "billdate"
  },
  {
    "label": "Punit",
    "align": "left",
    "sortable": false,
    "name": "punit",
    "field": "punit"
  },
  {
    "label": "Sunit",
    "align": "left",
    "sortable": false,
    "name": "sunit",
    "field": "sunit"
  },
  {
    "label": "Purvalue",
    "align": "left",
    "sortable": false,
    "name": "purvalue",
    "field": "purvalue"
  },
  {
    "label": "Purrate",
    "align": "left",
    "sortable": false,
    "name": "purrate",
    "field": "purrate"
  },
  {
    "label": "Industryid",
    "align": "left",
    "sortable": false,
    "name": "industryid",
    "field": "industryid"
  },
  {
    "label": "Repid",
    "align": "left",
    "sortable": false,
    "name": "repid",
    "field": "repid"
  },
  {
    "label": "Branchid",
    "align": "left",
    "sortable": false,
    "name": "branchid",
    "field": "branchid"
  },
  {
    "label": "Storeid",
    "align": "left",
    "sortable": false,
    "name": "storeid",
    "field": "storeid"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	storegrnlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Grnno",
    "align": "left",
    "sortable": false,
    "name": "grnno",
    "field": "grnno"
  },
  {
    "label": "Dt",
    "align": "left",
    "sortable": false,
    "name": "dt",
    "field": "dt"
  },
  {
    "label": "Ym",
    "align": "left",
    "sortable": false,
    "name": "ym",
    "field": "ym"
  },
  {
    "label": "Pc",
    "align": "left",
    "sortable": false,
    "name": "pc",
    "field": "pc"
  },
  {
    "label": "Customercode",
    "align": "left",
    "sortable": false,
    "name": "customercode",
    "field": "customercode"
  },
  {
    "label": "Customername",
    "align": "left",
    "sortable": false,
    "name": "customername",
    "field": "customername"
  },
  {
    "label": "Pharmacy Pocol",
    "align": "left",
    "sortable": false,
    "name": "pharmacy_pocol",
    "field": "pharmacy_pocol"
  },
  {
    "label": "Address1",
    "align": "left",
    "sortable": false,
    "name": "address1",
    "field": "address1"
  },
  {
    "label": "Address2",
    "align": "left",
    "sortable": false,
    "name": "address2",
    "field": "address2"
  },
  {
    "label": "Address3",
    "align": "left",
    "sortable": false,
    "name": "address3",
    "field": "address3"
  },
  {
    "label": "State",
    "align": "left",
    "sortable": false,
    "name": "state",
    "field": "state"
  },
  {
    "label": "Pincode",
    "align": "left",
    "sortable": false,
    "name": "pincode",
    "field": "pincode"
  },
  {
    "label": "Phone",
    "align": "left",
    "sortable": false,
    "name": "phone",
    "field": "phone"
  },
  {
    "label": "Fax",
    "align": "left",
    "sortable": false,
    "name": "fax",
    "field": "fax"
  },
  {
    "label": "Eemail",
    "align": "left",
    "sortable": false,
    "name": "eemail",
    "field": "eemail"
  },
  {
    "label": "Hospid",
    "align": "left",
    "sortable": false,
    "name": "hospid",
    "field": "hospid"
  },
  {
    "label": "Createdby",
    "align": "left",
    "sortable": false,
    "name": "createdby",
    "field": "createdby"
  },
  {
    "label": "Createddatetime",
    "align": "left",
    "sortable": false,
    "name": "createddatetime",
    "field": "createddatetime"
  },
  {
    "label": "Modifiedby",
    "align": "left",
    "sortable": false,
    "name": "modifiedby",
    "field": "modifiedby"
  },
  {
    "label": "Modifieddatetime",
    "align": "left",
    "sortable": false,
    "name": "modifieddatetime",
    "field": "modifieddatetime"
  },
  {
    "label": "Billno",
    "align": "left",
    "sortable": false,
    "name": "billno",
    "field": "billno"
  },
  {
    "label": "Billdt",
    "align": "left",
    "sortable": false,
    "name": "billdt",
    "field": "billdt"
  },
  {
    "label": "Brcode",
    "align": "left",
    "sortable": false,
    "name": "brcode",
    "field": "brcode"
  },
  {
    "label": "Pharmacyid",
    "align": "left",
    "sortable": false,
    "name": "pharmacyid",
    "field": "pharmacyid"
  },
  {
    "label": "Billtotalvalue",
    "align": "left",
    "sortable": false,
    "name": "billtotalvalue",
    "field": "billtotalvalue"
  },
  {
    "label": "Grntotalvalue",
    "align": "left",
    "sortable": false,
    "name": "grntotalvalue",
    "field": "grntotalvalue"
  },
  {
    "label": "Billdiscount",
    "align": "left",
    "sortable": false,
    "name": "billdiscount",
    "field": "billdiscount"
  },
  {
    "label": "Billupload",
    "align": "left",
    "sortable": false,
    "name": "billupload",
    "field": "billupload"
  },
  {
    "label": "Transport",
    "align": "left",
    "sortable": false,
    "name": "transport",
    "field": "transport"
  },
  {
    "label": "Anyother",
    "align": "left",
    "sortable": false,
    "name": "anyother",
    "field": "anyother"
  },
  {
    "label": "Remarks",
    "align": "left",
    "sortable": false,
    "name": "remarks",
    "field": "remarks"
  },
  {
    "label": "Grnvalue",
    "align": "left",
    "sortable": false,
    "name": "grnvalue",
    "field": "grnvalue"
  },
  {
    "label": "Pid",
    "align": "left",
    "sortable": false,
    "name": "pid",
    "field": "pid"
  },
  {
    "label": "Paymentno",
    "align": "left",
    "sortable": false,
    "name": "paymentno",
    "field": "paymentno"
  },
  {
    "label": "Paymentstatus",
    "align": "left",
    "sortable": false,
    "name": "paymentstatus",
    "field": "paymentstatus"
  },
  {
    "label": "Paidamount",
    "align": "left",
    "sortable": false,
    "name": "paidamount",
    "field": "paidamount"
  },
  {
    "label": "Industryid",
    "align": "left",
    "sortable": false,
    "name": "industryid",
    "field": "industryid"
  },
  {
    "label": "Repid",
    "align": "left",
    "sortable": false,
    "name": "repid",
    "field": "repid"
  },
  {
    "label": "Branchid",
    "align": "left",
    "sortable": false,
    "name": "branchid",
    "field": "branchid"
  },
  {
    "label": "Storeid",
    "align": "left",
    "sortable": false,
    "name": "storeid",
    "field": "storeid"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	storeissuelistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Reception",
    "align": "left",
    "sortable": false,
    "name": "reception",
    "field": "reception"
  },
  {
    "label": "Patientid",
    "align": "left",
    "sortable": false,
    "name": "patientid",
    "field": "patientid"
  },
  {
    "label": "Mrnno",
    "align": "left",
    "sortable": false,
    "name": "mrnno",
    "field": "mrnno"
  },
  {
    "label": "Patientname",
    "align": "left",
    "sortable": false,
    "name": "patientname",
    "field": "patientname"
  },
  {
    "label": "Age",
    "align": "left",
    "sortable": false,
    "name": "age",
    "field": "age"
  },
  {
    "label": "Gender",
    "align": "left",
    "sortable": false,
    "name": "gender",
    "field": "gender"
  },
  {
    "label": "Profilepic",
    "align": "left",
    "sortable": false,
    "name": "profilepic",
    "field": "profilepic"
  },
  {
    "label": "Mobile",
    "align": "left",
    "sortable": false,
    "name": "mobile",
    "field": "mobile"
  },
  {
    "label": "Ip Op",
    "align": "left",
    "sortable": false,
    "name": "ip_op",
    "field": "ip_op"
  },
  {
    "label": "Doctor",
    "align": "left",
    "sortable": false,
    "name": "doctor",
    "field": "doctor"
  },
  {
    "label": "Voucher Type",
    "align": "left",
    "sortable": false,
    "name": "voucher_type",
    "field": "voucher_type"
  },
  {
    "label": "Details",
    "align": "left",
    "sortable": false,
    "name": "details",
    "field": "details"
  },
  {
    "label": "Modeofpayment",
    "align": "left",
    "sortable": false,
    "name": "modeofpayment",
    "field": "modeofpayment"
  },
  {
    "label": "Amount",
    "align": "left",
    "sortable": false,
    "name": "amount",
    "field": "amount"
  },
  {
    "label": "Anydues",
    "align": "left",
    "sortable": false,
    "name": "anydues",
    "field": "anydues"
  },
  {
    "label": "Createdby",
    "align": "left",
    "sortable": false,
    "name": "createdby",
    "field": "createdby"
  },
  {
    "label": "Createddatetime",
    "align": "left",
    "sortable": false,
    "name": "createddatetime",
    "field": "createddatetime"
  },
  {
    "label": "Modifiedby",
    "align": "left",
    "sortable": false,
    "name": "modifiedby",
    "field": "modifiedby"
  },
  {
    "label": "Modifieddatetime",
    "align": "left",
    "sortable": false,
    "name": "modifieddatetime",
    "field": "modifieddatetime"
  },
  {
    "label": "Realizationamount",
    "align": "left",
    "sortable": false,
    "name": "realizationamount",
    "field": "realizationamount"
  },
  {
    "label": "Realizationstatus",
    "align": "left",
    "sortable": false,
    "name": "realizationstatus",
    "field": "realizationstatus"
  },
  {
    "label": "Realizationremarks",
    "align": "left",
    "sortable": false,
    "name": "realizationremarks",
    "field": "realizationremarks"
  },
  {
    "label": "Realizationbatchno",
    "align": "left",
    "sortable": false,
    "name": "realizationbatchno",
    "field": "realizationbatchno"
  },
  {
    "label": "Realizationdate",
    "align": "left",
    "sortable": false,
    "name": "realizationdate",
    "field": "realizationdate"
  },
  {
    "label": "Hospid",
    "align": "left",
    "sortable": false,
    "name": "hospid",
    "field": "hospid"
  },
  {
    "label": "Ridno",
    "align": "left",
    "sortable": false,
    "name": "ridno",
    "field": "ridno"
  },
  {
    "label": "Tidno",
    "align": "left",
    "sortable": false,
    "name": "tidno",
    "field": "tidno"
  },
  {
    "label": "Cid",
    "align": "left",
    "sortable": false,
    "name": "cid",
    "field": "cid"
  },
  {
    "label": "Partnername",
    "align": "left",
    "sortable": false,
    "name": "partnername",
    "field": "partnername"
  },
  {
    "label": "Payertype",
    "align": "left",
    "sortable": false,
    "name": "payertype",
    "field": "payertype"
  },
  {
    "label": "Dob",
    "align": "left",
    "sortable": false,
    "name": "dob",
    "field": "dob"
  },
  {
    "label": "Currency",
    "align": "left",
    "sortable": false,
    "name": "currency",
    "field": "currency"
  },
  {
    "label": "Discountremarks",
    "align": "left",
    "sortable": false,
    "name": "discountremarks",
    "field": "discountremarks"
  },
  {
    "label": "Remarks",
    "align": "left",
    "sortable": false,
    "name": "remarks",
    "field": "remarks"
  },
  {
    "label": "Patid",
    "align": "left",
    "sortable": false,
    "name": "patid",
    "field": "patid"
  },
  {
    "label": "Drdepartment",
    "align": "left",
    "sortable": false,
    "name": "drdepartment",
    "field": "drdepartment"
  },
  {
    "label": "Refferedby",
    "align": "left",
    "sortable": false,
    "name": "refferedby",
    "field": "refferedby"
  },
  {
    "label": "Billnumber",
    "align": "left",
    "sortable": false,
    "name": "billnumber",
    "field": "billnumber"
  },
  {
    "label": "Cardnumber",
    "align": "left",
    "sortable": false,
    "name": "cardnumber",
    "field": "cardnumber"
  },
  {
    "label": "Bankname",
    "align": "left",
    "sortable": false,
    "name": "bankname",
    "field": "bankname"
  },
  {
    "label": "Drid",
    "align": "left",
    "sortable": false,
    "name": "drid",
    "field": "drid"
  },
  {
    "label": "Billstatus",
    "align": "left",
    "sortable": false,
    "name": "billstatus",
    "field": "billstatus"
  },
  {
    "label": "Reportheader",
    "align": "left",
    "sortable": false,
    "name": "reportheader",
    "field": "reportheader"
  },
  {
    "label": "Pharid",
    "align": "left",
    "sortable": false,
    "name": "pharid",
    "field": "pharid"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	storesupplierslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Suppliercode",
    "align": "left",
    "sortable": false,
    "name": "suppliercode",
    "field": "suppliercode"
  },
  {
    "label": "Suppliername",
    "align": "left",
    "sortable": false,
    "name": "suppliername",
    "field": "suppliername"
  },
  {
    "label": "Abbreviation",
    "align": "left",
    "sortable": false,
    "name": "abbreviation",
    "field": "abbreviation"
  },
  {
    "label": "Creationdate",
    "align": "left",
    "sortable": false,
    "name": "creationdate",
    "field": "creationdate"
  },
  {
    "label": "Address1",
    "align": "left",
    "sortable": false,
    "name": "address1",
    "field": "address1"
  },
  {
    "label": "Address2",
    "align": "left",
    "sortable": false,
    "name": "address2",
    "field": "address2"
  },
  {
    "label": "Address3",
    "align": "left",
    "sortable": false,
    "name": "address3",
    "field": "address3"
  },
  {
    "label": "Citycode",
    "align": "left",
    "sortable": false,
    "name": "citycode",
    "field": "citycode"
  },
  {
    "label": "State",
    "align": "left",
    "sortable": false,
    "name": "state",
    "field": "state"
  },
  {
    "label": "Pincode",
    "align": "left",
    "sortable": false,
    "name": "pincode",
    "field": "pincode"
  },
  {
    "label": "Tngstnumber",
    "align": "left",
    "sortable": false,
    "name": "tngstnumber",
    "field": "tngstnumber"
  },
  {
    "label": "Phone",
    "align": "left",
    "sortable": false,
    "name": "phone",
    "field": "phone"
  },
  {
    "label": "Fax",
    "align": "left",
    "sortable": false,
    "name": "fax",
    "field": "fax"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Paymentmode",
    "align": "left",
    "sortable": false,
    "name": "paymentmode",
    "field": "paymentmode"
  },
  {
    "label": "Contactperson1",
    "align": "left",
    "sortable": false,
    "name": "contactperson1",
    "field": "contactperson1"
  },
  {
    "label": "Cp1address1",
    "align": "left",
    "sortable": false,
    "name": "cp1address1",
    "field": "cp1address1"
  },
  {
    "label": "Cp1address2",
    "align": "left",
    "sortable": false,
    "name": "cp1address2",
    "field": "cp1address2"
  },
  {
    "label": "Cp1address3",
    "align": "left",
    "sortable": false,
    "name": "cp1address3",
    "field": "cp1address3"
  },
  {
    "label": "Cp1citycode",
    "align": "left",
    "sortable": false,
    "name": "cp1citycode",
    "field": "cp1citycode"
  },
  {
    "label": "Cp1state",
    "align": "left",
    "sortable": false,
    "name": "cp1state",
    "field": "cp1state"
  },
  {
    "label": "Cp1pincode",
    "align": "left",
    "sortable": false,
    "name": "cp1pincode",
    "field": "cp1pincode"
  },
  {
    "label": "Cp1designation",
    "align": "left",
    "sortable": false,
    "name": "cp1designation",
    "field": "cp1designation"
  },
  {
    "label": "Cp1phone",
    "align": "left",
    "sortable": false,
    "name": "cp1phone",
    "field": "cp1phone"
  },
  {
    "label": "Cp1mobileno",
    "align": "left",
    "sortable": false,
    "name": "cp1mobileno",
    "field": "cp1mobileno"
  },
  {
    "label": "Cp1fax",
    "align": "left",
    "sortable": false,
    "name": "cp1fax",
    "field": "cp1fax"
  },
  {
    "label": "Cp1email",
    "align": "left",
    "sortable": false,
    "name": "cp1email",
    "field": "cp1email"
  },
  {
    "label": "Contactperson2",
    "align": "left",
    "sortable": false,
    "name": "contactperson2",
    "field": "contactperson2"
  },
  {
    "label": "Cp2address1",
    "align": "left",
    "sortable": false,
    "name": "cp2address1",
    "field": "cp2address1"
  },
  {
    "label": "Cp2address2",
    "align": "left",
    "sortable": false,
    "name": "cp2address2",
    "field": "cp2address2"
  },
  {
    "label": "Cp2address3",
    "align": "left",
    "sortable": false,
    "name": "cp2address3",
    "field": "cp2address3"
  },
  {
    "label": "Cp2citycode",
    "align": "left",
    "sortable": false,
    "name": "cp2citycode",
    "field": "cp2citycode"
  },
  {
    "label": "Cp2state",
    "align": "left",
    "sortable": false,
    "name": "cp2state",
    "field": "cp2state"
  },
  {
    "label": "Cp2pincode",
    "align": "left",
    "sortable": false,
    "name": "cp2pincode",
    "field": "cp2pincode"
  },
  {
    "label": "Cp2designation",
    "align": "left",
    "sortable": false,
    "name": "cp2designation",
    "field": "cp2designation"
  },
  {
    "label": "Cp2phone",
    "align": "left",
    "sortable": false,
    "name": "cp2phone",
    "field": "cp2phone"
  },
  {
    "label": "Cp2mobileno",
    "align": "left",
    "sortable": false,
    "name": "cp2mobileno",
    "field": "cp2mobileno"
  },
  {
    "label": "Cp2fax",
    "align": "left",
    "sortable": false,
    "name": "cp2fax",
    "field": "cp2fax"
  },
  {
    "label": "Cp2email",
    "align": "left",
    "sortable": false,
    "name": "cp2email",
    "field": "cp2email"
  },
  {
    "label": "Type",
    "align": "left",
    "sortable": false,
    "name": "type",
    "field": "type"
  },
  {
    "label": "Creditterms",
    "align": "left",
    "sortable": false,
    "name": "creditterms",
    "field": "creditterms"
  },
  {
    "label": "Remarks",
    "align": "left",
    "sortable": false,
    "name": "remarks",
    "field": "remarks"
  },
  {
    "label": "Tinnumber",
    "align": "left",
    "sortable": false,
    "name": "tinnumber",
    "field": "tinnumber"
  },
  {
    "label": "Universalsuppliercode",
    "align": "left",
    "sortable": false,
    "name": "universalsuppliercode",
    "field": "universalsuppliercode"
  },
  {
    "label": "Mobilenumber",
    "align": "left",
    "sortable": false,
    "name": "mobilenumber",
    "field": "mobilenumber"
  },
  {
    "label": "Pannumber",
    "align": "left",
    "sortable": false,
    "name": "pannumber",
    "field": "pannumber"
  },
  {
    "label": "Salesrepmobileno",
    "align": "left",
    "sortable": false,
    "name": "salesrepmobileno",
    "field": "salesrepmobileno"
  },
  {
    "label": "Gstnumber",
    "align": "left",
    "sortable": false,
    "name": "gstnumber",
    "field": "gstnumber"
  },
  {
    "label": "Tannumber",
    "align": "left",
    "sortable": false,
    "name": "tannumber",
    "field": "tannumber"
  },
  {
    "label": "Industryid",
    "align": "left",
    "sortable": false,
    "name": "industryid",
    "field": "industryid"
  },
  {
    "label": "Repid",
    "align": "left",
    "sortable": false,
    "name": "repid",
    "field": "repid"
  },
  {
    "label": "Branchid",
    "align": "left",
    "sortable": false,
    "name": "branchid",
    "field": "branchid"
  },
  {
    "label": "Storeid",
    "align": "left",
    "sortable": false,
    "name": "storeid",
    "field": "storeid"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	userslistheader: [
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Telephone",
    "align": "left",
    "sortable": false,
    "name": "telephone",
    "field": "telephone"
  },
  {
    "label": "Photo",
    "align": "left",
    "sortable": false,
    "name": "photo",
    "field": "photo"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	viewmascountrydatalistheader: [
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Code",
    "align": "left",
    "sortable": false,
    "name": "code",
    "field": "code"
  }
],
	viewmasindianpostaldatalistheader: [
  {
    "label": "Circlename",
    "align": "left",
    "sortable": false,
    "name": "circlename",
    "field": "circlename"
  },
  {
    "label": "Regionname",
    "align": "left",
    "sortable": false,
    "name": "regionname",
    "field": "regionname"
  },
  {
    "label": "Divisionname",
    "align": "left",
    "sortable": false,
    "name": "divisionname",
    "field": "divisionname"
  },
  {
    "label": "Officename",
    "align": "left",
    "sortable": false,
    "name": "officename",
    "field": "officename"
  },
  {
    "label": "Pincode",
    "align": "left",
    "sortable": false,
    "name": "pincode",
    "field": "pincode"
  },
  {
    "label": "Officetype",
    "align": "left",
    "sortable": false,
    "name": "officetype",
    "field": "officetype"
  },
  {
    "label": "Delivery",
    "align": "left",
    "sortable": false,
    "name": "delivery",
    "field": "delivery"
  },
  {
    "label": "District",
    "align": "left",
    "sortable": false,
    "name": "district",
    "field": "district"
  },
  {
    "label": "Statename",
    "align": "left",
    "sortable": false,
    "name": "statename",
    "field": "statename"
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  }
],
	weavingproductionlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Date",
    "align": "left",
    "sortable": false,
    "name": "date",
    "field": "date"
  },
  {
    "label": "From",
    "align": "left",
    "sortable": false,
    "name": "from",
    "field": "from"
  },
  {
    "label": "To",
    "align": "left",
    "sortable": false,
    "name": "to",
    "field": "to"
  },
  {
    "label": "Order",
    "align": "left",
    "sortable": false,
    "name": "order",
    "field": "order"
  },
  {
    "label": "Count",
    "align": "left",
    "sortable": false,
    "name": "count",
    "field": "count"
  },
  {
    "label": "Type",
    "align": "left",
    "sortable": false,
    "name": "type",
    "field": "type"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	workflistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Work Name",
    "align": "left",
    "sortable": false,
    "name": "work_name",
    "field": "work_name"
  },
  {
    "label": "Work Description",
    "align": "left",
    "sortable": false,
    "name": "work_description",
    "field": "work_description"
  },
  {
    "label": "Work Definition",
    "align": "left",
    "sortable": false,
    "name": "work_definition",
    "field": "work_definition"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	workflowlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Work Id",
    "align": "left",
    "sortable": false,
    "name": "work_id",
    "field": "work_id"
  },
  {
    "label": "Employee Id",
    "align": "left",
    "sortable": false,
    "name": "employee_id",
    "field": "employee_id"
  },
  {
    "label": "Location Id",
    "align": "left",
    "sortable": false,
    "name": "location_id",
    "field": "location_id"
  },
  {
    "label": "Equipment Id",
    "align": "left",
    "sortable": false,
    "name": "equipment_id",
    "field": "equipment_id"
  },
  {
    "label": "Work Name",
    "align": "left",
    "sortable": false,
    "name": "work_name",
    "field": "work_name"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	workflowmanagementlistheader: [
  {
    "label": "Workflow Id",
    "align": "left",
    "sortable": false,
    "name": "workflow_id",
    "field": "workflow_id"
  },
  {
    "label": "Progress Count",
    "align": "left",
    "sortable": false,
    "name": "progress_count",
    "field": "progress_count"
  },
  {
    "label": "Workflow Description",
    "align": "left",
    "sortable": false,
    "name": "workflow_description",
    "field": "workflow_description"
  },
  {
    "label": "Workflow Definition",
    "align": "left",
    "sortable": false,
    "name": "workflow_definition",
    "field": "workflow_definition"
  },
  {
    "label": "Workflow Status",
    "align": "left",
    "sortable": false,
    "name": "workflow_status",
    "field": "workflow_status"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	wpdproductionlistheader: [
  {
    "label": "Date",
    "align": "left",
    "sortable": false,
    "name": "date",
    "field": "date"
  },
  {
    "label": "From",
    "align": "left",
    "sortable": false,
    "name": "from",
    "field": "from"
  },
  {
    "label": "To",
    "align": "left",
    "sortable": false,
    "name": "to",
    "field": "to"
  },
  {
    "label": "Order",
    "align": "left",
    "sortable": false,
    "name": "order",
    "field": "order"
  },
  {
    "label": "Count",
    "align": "left",
    "sortable": false,
    "name": "count",
    "field": "count"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	type: [    
{value: "Input", label: "Input"},
	{value: "Output", label: "Output"},
	{value: "Wastage", label: "Wastage"}
    ],

    exportFormats: {
        print: {
			label: 'Print',
			color: 'blue',
            icon: 'print',
            id: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			color: 'red',
            icon: 'picture_as_pdf',
            id: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
			color: 'green',
            icon: 'grid_on',
            id: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
			color: 'teal',
            icon: 'grid_on',
            id: 'csv',
            ext: 'csv',
        },
    },
    
}