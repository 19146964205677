
import { route } from 'quasar/wrappers'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'

import { useAuth } from 'src/composables/auth';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [{
	name: 'main',
	path: '/',
	component: () => import('layouts/MainLayout.vue'),
	children: [
		//Dashboard routes


//employee routes
		{
			path: '/employee/:fieldName?/:fieldValue?',
			name: 'employeelist',
			component: () => import('pages/employee/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/employee/view/:id', 
			name: 'employeeview', 
			component: () => import('pages/employee/view.vue'), 
			props: true 
		},
		{ 
			path: '/employee/add', 
			name: 'employeeadd', 
			component: () => import('pages/employee/add.vue'), 
			props: true 
		},
		{ 
			path: '/employee/edit/:id', 
			name: 'employeeedit', 
			component: () => import('pages/employee/edit.vue'), 
			props: true 
		},

//equipment routes
		{
			path: '/equipment/:fieldName?/:fieldValue?',
			name: 'equipmentlist',
			component: () => import('pages/equipment/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/equipment/view/:id', 
			name: 'equipmentview', 
			component: () => import('pages/equipment/view.vue'), 
			props: true 
		},
		{ 
			path: '/equipment/add', 
			name: 'equipmentadd', 
			component: () => import('pages/equipment/add.vue'), 
			props: true 
		},
		{ 
			path: '/equipment/edit/:id', 
			name: 'equipmentedit', 
			component: () => import('pages/equipment/edit.vue'), 
			props: true 
		},
		{
			path: '/equipment/wpd/:fieldName?/:fieldValue?',
			name: 'equipmentwpd',
			component: () => import('pages/equipment/wpd.vue'),
			props: route => passRouteToProps(route)
		},
		{
			path: '/equipment/weaving/:fieldName?/:fieldValue?',
			name: 'equipmentweaving',
			component: () => import('pages/equipment/weaving.vue'),
			props: route => passRouteToProps(route)
		},

//equipmentmaintenance routes
		{
			path: '/equipmentmaintenance/:fieldName?/:fieldValue?',
			name: 'equipmentmaintenancelist',
			component: () => import('pages/equipmentmaintenance/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/equipmentmaintenance/view/:id', 
			name: 'equipmentmaintenanceview', 
			component: () => import('pages/equipmentmaintenance/view.vue'), 
			props: true 
		},
		{ 
			path: '/equipmentmaintenance/add', 
			name: 'equipmentmaintenanceadd', 
			component: () => import('pages/equipmentmaintenance/add.vue'), 
			props: true 
		},
		{ 
			path: '/equipmentmaintenance/edit/:id', 
			name: 'equipmentmaintenanceedit', 
			component: () => import('pages/equipmentmaintenance/edit.vue'), 
			props: true 
		},

//equipmentrepairs routes
		{
			path: '/equipmentrepairs/:fieldName?/:fieldValue?',
			name: 'equipmentrepairslist',
			component: () => import('pages/equipmentrepairs/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/equipmentrepairs/view/:id', 
			name: 'equipmentrepairsview', 
			component: () => import('pages/equipmentrepairs/view.vue'), 
			props: true 
		},
		{ 
			path: '/equipmentrepairs/add', 
			name: 'equipmentrepairsadd', 
			component: () => import('pages/equipmentrepairs/add.vue'), 
			props: true 
		},
		{ 
			path: '/equipmentrepairs/edit/:id', 
			name: 'equipmentrepairsedit', 
			component: () => import('pages/equipmentrepairs/edit.vue'), 
			props: true 
		},

//equipmentworkf routes
		{
			path: '/equipmentworkf/:fieldName?/:fieldValue?',
			name: 'equipmentworkflist',
			component: () => import('pages/equipmentworkf/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/equipmentworkf/view/:id', 
			name: 'equipmentworkfview', 
			component: () => import('pages/equipmentworkf/view.vue'), 
			props: true 
		},
		{ 
			path: '/equipmentworkf/add', 
			name: 'equipmentworkfadd', 
			component: () => import('pages/equipmentworkf/add.vue'), 
			props: true 
		},
		{ 
			path: '/equipmentworkf/edit/:id', 
			name: 'equipmentworkfedit', 
			component: () => import('pages/equipmentworkf/edit.vue'), 
			props: true 
		},

//equipmentworkflow routes
		{
			path: '/equipmentworkflow/:fieldName?/:fieldValue?',
			name: 'equipmentworkflowlist',
			component: () => import('pages/equipmentworkflow/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/equipmentworkflow/view/:id', 
			name: 'equipmentworkflowview', 
			component: () => import('pages/equipmentworkflow/view.vue'), 
			props: true 
		},
		{ 
			path: '/equipmentworkflow/add', 
			name: 'equipmentworkflowadd', 
			component: () => import('pages/equipmentworkflow/add.vue'), 
			props: true 
		},
		{ 
			path: '/equipmentworkflow/edit/:id', 
			name: 'equipmentworkflowedit', 
			component: () => import('pages/equipmentworkflow/edit.vue'), 
			props: true 
		},

//equipmentworkflowmanagement routes
		{
			path: '/equipmentworkflowmanagement/:fieldName?/:fieldValue?',
			name: 'equipmentworkflowmanagementlist',
			component: () => import('pages/equipmentworkflowmanagement/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/equipmentworkflowmanagement/view/:id', 
			name: 'equipmentworkflowmanagementview', 
			component: () => import('pages/equipmentworkflowmanagement/view.vue'), 
			props: true 
		},
		{ 
			path: '/equipmentworkflowmanagement/add', 
			name: 'equipmentworkflowmanagementadd', 
			component: () => import('pages/equipmentworkflowmanagement/add.vue'), 
			props: true 
		},
		{ 
			path: '/equipmentworkflowmanagement/edit/:id', 
			name: 'equipmentworkflowmanagementedit', 
			component: () => import('pages/equipmentworkflowmanagement/edit.vue'), 
			props: true 
		},

//industry routes
		{
			path: '/industry/:fieldName?/:fieldValue?',
			name: 'industrylist',
			component: () => import('pages/industry/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/industry/view/:id', 
			name: 'industryview', 
			component: () => import('pages/industry/view.vue'), 
			props: true 
		},
		{ 
			path: '/industry/add', 
			name: 'industryadd', 
			component: () => import('pages/industry/add.vue'), 
			props: true 
		},
		{ 
			path: '/industry/edit/:id', 
			name: 'industryedit', 
			component: () => import('pages/industry/edit.vue'), 
			props: true 
		},

//iotdata routes
		{
			path: '/iotdata/:fieldName?/:fieldValue?',
			name: 'iotdatalist',
			component: () => import('pages/iotdata/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/iotdata/view/:id', 
			name: 'iotdataview', 
			component: () => import('pages/iotdata/view.vue'), 
			props: true 
		},
		{ 
			path: '/iotdata/add', 
			name: 'iotdataadd', 
			component: () => import('pages/iotdata/add.vue'), 
			props: true 
		},
		{ 
			path: '/iotdata/edit/:id', 
			name: 'iotdataedit', 
			component: () => import('pages/iotdata/edit.vue'), 
			props: true 
		},

//location routes
		{
			path: '/location/:fieldName?/:fieldValue?',
			name: 'locationlist',
			component: () => import('pages/location/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/location/view/:id', 
			name: 'locationview', 
			component: () => import('pages/location/view.vue'), 
			props: true 
		},
		{ 
			path: '/location/add', 
			name: 'locationadd', 
			component: () => import('pages/location/add.vue'), 
			props: true 
		},
		{ 
			path: '/location/edit/:id', 
			name: 'locationedit', 
			component: () => import('pages/location/edit.vue'), 
			props: true 
		},

//notification routes
		{
			path: '/notification/:fieldName?/:fieldValue?',
			name: 'notificationlist',
			component: () => import('pages/notification/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/notification/view/:id', 
			name: 'notificationview', 
			component: () => import('pages/notification/view.vue'), 
			props: true 
		},
		{ 
			path: '/notification/add', 
			name: 'notificationadd', 
			component: () => import('pages/notification/add.vue'), 
			props: true 
		},
		{ 
			path: '/notification/edit/:id', 
			name: 'notificationedit', 
			component: () => import('pages/notification/edit.vue'), 
			props: true 
		},

//product routes
		{
			path: '/product/:fieldName?/:fieldValue?',
			name: 'productlist',
			component: () => import('pages/product/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/product/view/:id', 
			name: 'productview', 
			component: () => import('pages/product/view.vue'), 
			props: true 
		},
		{ 
			path: '/product/add', 
			name: 'productadd', 
			component: () => import('pages/product/add.vue'), 
			props: true 
		},
		{ 
			path: '/product/edit/:id', 
			name: 'productedit', 
			component: () => import('pages/product/edit.vue'), 
			props: true 
		},

//production routes
		{
			path: '/production/:fieldName?/:fieldValue?',
			name: 'productionlist',
			component: () => import('pages/production/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/production/view/:id', 
			name: 'productionview', 
			component: () => import('pages/production/view.vue'), 
			props: true 
		},
		{ 
			path: '/production/add', 
			name: 'productionadd', 
			component: () => import('pages/production/add.vue'), 
			props: true 
		},
		{ 
			path: '/production/edit/:id', 
			name: 'productionedit', 
			component: () => import('pages/production/edit.vue'), 
			props: true 
		},

//productionmonth routes
		{
			path: '/productionmonth/:fieldName?/:fieldValue?',
			name: 'productionmonthlist',
			component: () => import('pages/productionmonth/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/productionmonth/view/:id', 
			name: 'productionmonthview', 
			component: () => import('pages/productionmonth/view.vue'), 
			props: true 
		},
		{ 
			path: '/productionmonth/add', 
			name: 'productionmonthadd', 
			component: () => import('pages/productionmonth/add.vue'), 
			props: true 
		},
		{ 
			path: '/productionmonth/edit/:id', 
			name: 'productionmonthedit', 
			component: () => import('pages/productionmonth/edit.vue'), 
			props: true 
		},

//store routes
		{
			path: '/store/:fieldName?/:fieldValue?',
			name: 'storelist',
			component: () => import('pages/store/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/store/view/:id', 
			name: 'storeview', 
			component: () => import('pages/store/view.vue'), 
			props: true 
		},
		{ 
			path: '/store/add', 
			name: 'storeadd', 
			component: () => import('pages/store/add.vue'), 
			props: true 
		},
		{ 
			path: '/store/edit/:id', 
			name: 'storeedit', 
			component: () => import('pages/store/edit.vue'), 
			props: true 
		},

//storebatchmas routes
		{
			path: '/storebatchmas/:fieldName?/:fieldValue?',
			name: 'storebatchmaslist',
			component: () => import('pages/storebatchmas/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/storebatchmas/view/:id', 
			name: 'storebatchmasview', 
			component: () => import('pages/storebatchmas/view.vue'), 
			props: true 
		},
		{ 
			path: '/storebatchmas/add', 
			name: 'storebatchmasadd', 
			component: () => import('pages/storebatchmas/add.vue'), 
			props: true 
		},
		{ 
			path: '/storebatchmas/edit/:id', 
			name: 'storebatchmasedit', 
			component: () => import('pages/storebatchmas/edit.vue'), 
			props: true 
		},

//storegrn routes
		{
			path: '/storegrn/:fieldName?/:fieldValue?',
			name: 'storegrnlist',
			component: () => import('pages/storegrn/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/storegrn/view/:id', 
			name: 'storegrnview', 
			component: () => import('pages/storegrn/view.vue'), 
			props: true 
		},
		{ 
			path: '/storegrn/add', 
			name: 'storegrnadd', 
			component: () => import('pages/storegrn/add.vue'), 
			props: true 
		},
		{ 
			path: '/storegrn/edit/:id', 
			name: 'storegrnedit', 
			component: () => import('pages/storegrn/edit.vue'), 
			props: true 
		},

//storeissue routes
		{
			path: '/storeissue/:fieldName?/:fieldValue?',
			name: 'storeissuelist',
			component: () => import('pages/storeissue/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/storeissue/view/:id', 
			name: 'storeissueview', 
			component: () => import('pages/storeissue/view.vue'), 
			props: true 
		},
		{ 
			path: '/storeissue/add', 
			name: 'storeissueadd', 
			component: () => import('pages/storeissue/add.vue'), 
			props: true 
		},
		{ 
			path: '/storeissue/edit/:id', 
			name: 'storeissueedit', 
			component: () => import('pages/storeissue/edit.vue'), 
			props: true 
		},

//storesuppliers routes
		{
			path: '/storesuppliers/:fieldName?/:fieldValue?',
			name: 'storesupplierslist',
			component: () => import('pages/storesuppliers/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/storesuppliers/view/:id', 
			name: 'storesuppliersview', 
			component: () => import('pages/storesuppliers/view.vue'), 
			props: true 
		},
		{ 
			path: '/storesuppliers/add', 
			name: 'storesuppliersadd', 
			component: () => import('pages/storesuppliers/add.vue'), 
			props: true 
		},
		{ 
			path: '/storesuppliers/edit/:id', 
			name: 'storesuppliersedit', 
			component: () => import('pages/storesuppliers/edit.vue'), 
			props: true 
		},

//users routes
		{
			path: '/users/:fieldName?/:fieldValue?',
			name: 'userslist',
			component: () => import('pages/users/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/users/view/:id', 
			name: 'usersview', 
			component: () => import('pages/users/view.vue'), 
			props: true 
		},
		{ 
			path: '/index/register', 
			name: 'usersuserregister', 
			component: () => import('pages/index/userregister.vue'), 
			props: true 
		},
		{ 
			path: '/account/edit', 
			name: 'usersaccountedit', 
			component: () => import('pages/account/accountedit.vue'), 
			props: true 
		},
		{ 
			path: '/account', 
			name: 'usersaccountview', 
			component: () => import('pages/account/accountview.vue'), 
			props: true 
		},
		{ 
			path: '/users/add', 
			name: 'usersadd', 
			component: () => import('pages/users/add.vue'), 
			props: true 
		},
		{ 
			path: '/users/edit/:id', 
			name: 'usersedit', 
			component: () => import('pages/users/edit.vue'), 
			props: true 
		},

//viewmascountrydata routes
		{
			path: '/viewmascountrydata/:fieldName?/:fieldValue?',
			name: 'viewmascountrydatalist',
			component: () => import('pages/viewmascountrydata/list.vue'),
			props: route => passRouteToProps(route)
		},

//viewmasindianpostaldata routes
		{
			path: '/viewmasindianpostaldata/:fieldName?/:fieldValue?',
			name: 'viewmasindianpostaldatalist',
			component: () => import('pages/viewmasindianpostaldata/list.vue'),
			props: route => passRouteToProps(route)
		},

//weavingproduction routes
		{
			path: '/weavingproduction/:fieldName?/:fieldValue?',
			name: 'weavingproductionlist',
			component: () => import('pages/weavingproduction/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/weavingproduction/view/:id', 
			name: 'weavingproductionview', 
			component: () => import('pages/weavingproduction/view.vue'), 
			props: true 
		},
		{ 
			path: '/weavingproduction/add', 
			name: 'weavingproductionadd', 
			component: () => import('pages/weavingproduction/add.vue'), 
			props: true 
		},
		{ 
			path: '/weavingproduction/edit/:id', 
			name: 'weavingproductionedit', 
			component: () => import('pages/weavingproduction/edit.vue'), 
			props: true 
		},

//workf routes
		{
			path: '/workf/:fieldName?/:fieldValue?',
			name: 'workflist',
			component: () => import('pages/workf/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/workf/view/:id', 
			name: 'workfview', 
			component: () => import('pages/workf/view.vue'), 
			props: true 
		},
		{ 
			path: '/workf/add', 
			name: 'workfadd', 
			component: () => import('pages/workf/add.vue'), 
			props: true 
		},
		{ 
			path: '/workf/edit/:id', 
			name: 'workfedit', 
			component: () => import('pages/workf/edit.vue'), 
			props: true 
		},

//workflow routes
		{
			path: '/workflow/:fieldName?/:fieldValue?',
			name: 'workflowlist',
			component: () => import('pages/workflow/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/workflow/view/:id', 
			name: 'workflowview', 
			component: () => import('pages/workflow/view.vue'), 
			props: true 
		},
		{ 
			path: '/workflow/add', 
			name: 'workflowadd', 
			component: () => import('pages/workflow/add.vue'), 
			props: true 
		},
		{ 
			path: '/workflow/edit/:id', 
			name: 'workflowedit', 
			component: () => import('pages/workflow/edit.vue'), 
			props: true 
		},

//workflowmanagement routes
		{
			path: '/workflowmanagement/:fieldName?/:fieldValue?',
			name: 'workflowmanagementlist',
			component: () => import('pages/workflowmanagement/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/workflowmanagement/view/:id', 
			name: 'workflowmanagementview', 
			component: () => import('pages/workflowmanagement/view.vue'), 
			props: true 
		},
		{ 
			path: '/workflowmanagement/add', 
			name: 'workflowmanagementadd', 
			component: () => import('pages/workflowmanagement/add.vue'), 
			props: true 
		},
		{ 
			path: '/workflowmanagement/edit/:id', 
			name: 'workflowmanagementedit', 
			component: () => import('pages/workflowmanagement/edit.vue'), 
			props: true 
		},

//wpdproduction routes
		{
			path: '/wpdproduction/:fieldName?/:fieldValue?',
			name: 'wpdproductionlist',
			component: () => import('pages/wpdproduction/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/wpdproduction/view/:id', 
			name: 'wpdproductionview', 
			component: () => import('pages/wpdproduction/view.vue'), 
			props: true 
		},
		{ 
			path: '/wpdproduction/add', 
			name: 'wpdproductionadd', 
			component: () => import('pages/wpdproduction/add.vue'), 
			props: true 
		},
		{ 
			path: '/wpdproduction/edit/:id', 
			name: 'wpdproductionedit', 
			component: () => import('pages/wpdproduction/edit.vue'), 
			props: true 
		},

		{ 
			path: '/storehome', 
			name: 'storehome', 
			component: () => import('pages/custom/storehome.vue'), 
			props: true 
		},
		{ 
			path: '/wpdhome', 
			name: 'wpdhome', 
			component: () => import('pages/custom/wpdhome.vue'), 
			props: true 
		},
		{ 
			path: '/weavinghome', 
			name: 'weavinghome', 
			component: () => import('pages/custom/weavinghome.vue'), 
			props: true 
		},
		{ 
			path: '/greyinspectionhome', 
			name: 'greyinspectionhome', 
			component: () => import('pages/custom/greyinspectionhome.vue'), 
			props: true 
		},
		{ 
			path: '/processinghome', 
			name: 'processinghome', 
			component: () => import('pages/custom/processinghome.vue'), 
			props: true 
		},
		{ 
			path: '/processinginspectionhome', 
			name: 'processinginspectionhome', 
			component: () => import('pages/custom/processinginspectionhome.vue'), 
			props: true 
		},
		{ 
			path: '/confectionhome', 
			name: 'confectionhome', 
			component: () => import('pages/custom/confectionhome.vue'), 
			props: true 
		},
		{ 
			path: '/finishedgoodshome', 
			name: 'finishedgoodshome', 
			component: () => import('pages/custom/finishedgoodshome.vue'), 
			props: true 
		},
		
		
//Password reset routes
		{ 
			path: '/index/forgotpassword', 
			name: 'forgotpassword', 
			component: () => import('pages/index/forgotpassword.vue') 
		},
		{ 
			path: '/index/resetpassword', 
			name: 'resetpassword', 
			component: () => import('pages/index/resetpassword.vue') 
		},
		{ 
			path: '/index/resetpassword_completed', 
			name: 'resetpassword_completed', 
			component: () => import('pages/index/resetpassword_completed.vue') 
		},
		
		

		//Error pages
		{ path:  '/error/forbidden', name: 'forbidden',  component: () => import('pages/errors/forbidden.vue') },
		{ path: '/error/notfound',  name: 'notfound', component: () => import('pages/errors/pagenotfound.vue') }
	],
	
	},
	{ path: '/:catchAll(.*)*', component: () => import('pages/errors/pagenotfound.vue') }
];

export default route(async function () {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE == 'history' ? createWebHistory : createWebHashHistory)
	
	const mainRoute = routes.find(x => x.name = "main");
	
	const auth = useAuth();

	
	//get current user data from api on first load
	const { user } = await auth.getUserData();
	if(user){
		const homeRoute = {
			path: '/', 
			alias: '/home', 
			name: 'home', 
			component: () => import('pages/home/home.vue') 
		}
		mainRoute.children.push(homeRoute);
	}
	else{
		const indexRoute = { 
			path: '/',  
			alias: '/home', 
			name: 'index', 
			component: () => import('pages/index/index.vue') 
		}
		mainRoute.children.push(indexRoute);
	}

	const Router = createRouter({
	scrollBehavior(to, from, savedPostion){
		if(savedPostion) return savedPostion;
		return { top:0 }
	},
    routes,
    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE)
  })
  return Router
})

